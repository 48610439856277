<template>
<v-card>
  <DeleteDialog ref="deleteDialog" v-on:delete-dialog-confirmation="deleteCurrentItem($event)"></DeleteDialog>
  <EditorDialog ref="editDialog" :on-delete="showDeleteDialog"></EditorDialog>
<v-card-title>
  {{title}}
  <v-spacer></v-spacer>
  <v-text-field
      v-model="options.search"
      append-icon="mdi-magnify"
      append-outer-icon="mdi-cog"
      label="Search"
      single-line
      clearable
      hide-details
      @click:append="getDataFromApi"
      @click:append-outer="ui.showSearchOptions = !ui.showSearchOptions"
      @keydown.enter="getDataFromApi"
  ></v-text-field>
</v-card-title>
<v-container v-if="ui.showSearchOptions">
  <v-toolbar flat>
    <v-toolbar-title>Advanced Search Options</v-toolbar-title>
    <v-spacer></v-spacer>
    <div>
      <v-switch
          v-model="searchOptions.global_search"
          label="Also Search inside texts (slow)"
      ></v-switch>
    </div>
  </v-toolbar>
  <v-row  no-gutters>
    <v-col cols="3">
      <v-switch
          v-model="searchOptions.fields.email"
          :label="`E-Mails`"
          :disabled="!searchOptions.fields.username && !searchOptions.fields.first_name && !searchOptions.fields.last_name && !searchOptions.fields.user_settings"
      ></v-switch>
    </v-col>
    <v-col cols="3">
      <v-switch
          v-model="searchOptions.fields.username"
          :label="`usernames`"
          :disabled="!searchOptions.fields.email && !searchOptions.fields.first_name && !searchOptions.fields.last_name  && !searchOptions.fields.user_settings"
      ></v-switch>
    </v-col>
    <v-col cols="3">
      <v-switch
          v-model="searchOptions.fields.first_name"
          :label="`First Names`"
          :disabled="!searchOptions.fields.username && !searchOptions.fields.last_name && !searchOptions.fields.email && !searchOptions.fields.user_settings"
      ></v-switch>
    </v-col>
    <v-col cols="3">
      <v-switch
          v-model="searchOptions.fields.last_name"
          :label="`Last Names`"
          :disabled="!searchOptions.fields.username && !searchOptions.fields.first_name && !searchOptions.fields.email && !searchOptions.fields.user_settings"
      ></v-switch>
    </v-col>
    <v-col cols="3" v-if="role === null">
      <v-switch
          v-model="searchOptions.roles.teacher"
          :label="`Teachers Only`"
          :disabled="searchOptions.roles.student"
      ></v-switch>
    </v-col>
    <v-col cols="3" v-if="role === null">
      <v-switch
          v-model="searchOptions.fields.student"
          :label="`Students Only`"
          :disabled="searchOptions.roles.teacher"
      ></v-switch>
    </v-col>
    <v-col cols="3" v-if="role === null">
      <v-switch
          v-model="searchOptions.fields.user_settings"
          :label="`Subscription Data / School Name / Job Title`"
      ></v-switch>
    </v-col>
  </v-row>
</v-container>
<v-progress-linear v-if="progress" indeterminate></v-progress-linear>
<v-data-table
    :headers="headers"
    :items="users"
    :options.sync="options"
    :server-items-length="totalUsers"
    :loading="loading"
    :search="options.search"
    class="elevation-1"
    @click:row="openItem"
>
  <template v-slot:body.prepend>
    <tr>
      <td>
        -
      </td>
      <td>
        -
      </td>
      <td>
        <v-text-field @keydown.enter="getDataFromApi" v-model="searchOptions.filters.username" placeholder="username"></v-text-field>
      </td>
      <td>
        <v-text-field @keydown.enter="getDataFromApi" v-model="searchOptions.filters.email" placeholder="e-mail"></v-text-field>
      </td>
      <td>
        <v-text-field @keydown.enter="getDataFromApi" v-model="searchOptions.filters.first_name" placeholder="first name"></v-text-field>
      </td>
      <td>
        <v-text-field @keydown.enter="getDataFromApi" v-model="searchOptions.filters.last_name" placeholder="last name"></v-text-field>
      </td>
      <td>
        -
      </td>
      <td>
        -
      </td>
    </tr>
  </template>
  <template v-slot:item.actions="{ item }">
    <v-icon
        class="mr-2"
        @click.stop.prevent="ADD_USERS_FOR_BULK_ACTIONS([item])">
      mdi-plus
    </v-icon>
    <v-icon
        class="mr-2"
        @click.stop.prevent="impersonate(item)">
      mdi-login
    </v-icon>
    <v-icon
        class="mr-2"
        @click.stop.prevent="openItem(item)"
    >
      mdi-pencil
    </v-icon>
    <v-icon color.hover="red"
            @click.stop.prevent="showDeleteDialog(item)"
    >
      mdi-delete
    </v-icon>
  </template>
  <template v-slot:item.roles="{ item }">
    <v-chip v-for="role in item.roles" :key="role"
            close-icon="mdi-close-outline"
            :color="getRoleColor(role)"
            label
            x-small
    >{{getRoleName(role)}}</v-chip>
    <v-chip v-if="isSubscription(item)"
            color="green"
            label
            x-small
    >
      <v-icon x-small left>
        mdi-currency-usd
    </v-icon>PREMIUM
    </v-chip>
  </template>
</v-data-table>
</v-card>
</template>
<script>
import _ from 'lodash'
import EditorDialog from "@/components/UserEditorDialog";
import {ROLE_ADMIN, ROLE_STUDENT, ROLE_TEACHER, ROLES} from "@/constants";
import DeleteDialog from "@/components/DeleteDialog";
import {EventBus, USER_UPDATED_EVENT} from "@/eventbus";
import {mapMutations, mapState} from "vuex";

export default {

  name: 'UsersTable',
  components: {
    EditorDialog, DeleteDialog
  },
  props: {
    title: {
      type: String,
      default: 'Users'
    },
    role: {
      type: Number,
      default: null
    }
  },
  data: () => {
    return {
      cancelToken: null,
      ui: {
        showSearchOptions: false
      },
      searchOptions: {
        global_search: false,
        fields: {
          email: true,
          username: true,
          first_name: false,
          last_name: false,
          user_settings: false
        },
        filters: {
          email: '',
          username: '',
          first_name: '',
          last_name: '',
          user_settings: ''
        },
        roles: {
          teacher: false,
          student: false
        }
      },
      progress: false,
      dialog: false,
      dialogDelete: false,
      lastFetchQuery: '',
      headers: [
        {text: 'Role', value: 'roles', sortable :false},
        {text: 'id', value: 'id'},
        {text: 'username', value: 'username'},
        {text: 'E-Mail', value: 'email'},
        {text: 'First Name', value: 'first_name'},
        {text: 'Last Name', value: 'last_name'},
        {text: 'Last seen at', value: 'last_login_date'},
        {text: 'Created at', value: 'create_date'},
        {text: 'Actions', value: 'actions', sortable: false, width: '200px'},
      ],
      users: [],
      options: {
        search: '',
        sortBy: ['id'],
        sortDesc: [false]
      },
      totalUsers: 0,
      loading: false,
      editedItem: {
        name: '',
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },
      defaultItem: {
        name: '',
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },
    }
  },
  computed: {
    ...mapState(['groups']),
    isCognitoAdmin() {
      return this.groups.indexOf('admin') > -1
    },
    querySeachParams() {
      let url = ''
      if (!_.isEmpty(this.options.search) || this.options.search.length > 2) {
        url = 'query=' + encodeURIComponent(this.options.search);
        const search_fields = _.keys(_.pickBy(this.searchOptions.fields));
        if (!_.isEmpty(search_fields)) url += '&query_fields=' + _.join(search_fields, ',')
        // if (!_.isEmpty(filter_fields)) url += '&filters[]=' + _.join(search_fields, ',')
        if (this.searchOptions.global_search) url+='&query_search_inline=true'
        if (this.searchOptions.roles.teacher) {
          url+='&role=4'
        } else if (this.searchOptions.roles.student) {
          url+='&role=3'
        }
      }
      const url_with_filters = _.reduce(_.toPairs(this.searchOptions.filters), (acc, pair) => {
        if (!_.isEmpty(pair[1])) {
          if (!_.isEmpty(acc)) acc += '&'
          acc += `${pair[0]}=${encodeURIComponent(pair[1])}`
        }
        return acc;
      }, url);
      return _.isEmpty(url_with_filters) ? url : url_with_filters;
    }
  },
  watch: {
    options: {
      handler() {
        this.debouncedGetDataFromApi()
      },
      deep: true,
    },
    'searchOptions.filters': {
      handler() {
        this.debouncedGetDataFromApi()
      },
      deep: true,
    },
    'searchOptions.fields.user_settings': {
      handler(newVal) {
        this.searchOptions.global_search = newVal
        this.searchOptions.fields.username = !newVal
        this.searchOptions.fields.email = !newVal
      }
    }
  },
  created() {
    this.debouncedGetDataFromApi = _.debounce(function () {
      this.getDataFromApi()
    }, 500, {leading: false})
    EventBus.$on(USER_UPDATED_EVENT, (item) => {
      const user = _.find(this.users, {id: item.id})
      if (user) {
        _.assign(user, item);
      }
    })
  },
  methods: {
    ...mapMutations(['ADD_USERS_FOR_BULK_ACTIONS']),
    async impersonate(item) {
      window.open(`https://readtheory.org/j_spring_security_switch_user?j_username=${item.username}`)
    },
    isTeacher(item) {
      return item && item.roles && item.roles.indexOf(ROLE_TEACHER) > -1
    },
    isStudent(item) {
      return item && item.roles && item.roles.indexOf(ROLE_STUDENT) > -1
    },
    isAdmin(item) {
      return item && item.roles && item.roles.indexOf(ROLE_ADMIN) > -1
    },
    getRoleColor(roleId) {
      if (roleId === ROLE_TEACHER) return 'orange'
      if (roleId === ROLE_STUDENT) return 'yellow'
      if (roleId === ROLE_ADMIN) return 'red'
      return 'blue'
    },
    getRoleName(roleId) {
      return ROLES[roleId];
    },
    isSubscription(item) {
      return _.result(item, 'user_settings.subscription.active', false)
    },
    editItem() {

    },

    showDeleteDialog(item) {
      this.$refs.deleteDialog.show(item.username, item);
    },

    async deleteCurrentItem(item) {
      this.progress = true;
      this.progress = true;
      let url = `/user/delete?id=${item.id}`;
      const {data, status} = await this.axios(url);
      if (status !== 200) {
        this.progress = false;
        this.$root.$emit('notify', {message: `Server returned with status code: ${status}`, type: 'error'})
        return;
      }
      if (data.success === true || data.success === 'true') {
        this.progress = false;
        this.$root.$emit('notify', {message: `User ${item.username} deleted.`, type: 'success'})
        this.users = this.users.filter(user => user.id !== item.id)
      } else {
        this.progress = false;
        this.$root.$emit('notify', {message: `Server: ${data.message}`, type: 'error'})
      }
    },


    openItem(item) {
      this.$refs.editDialog.show(item);
    },

    async getDataFromApi() {
      const {sortBy, sortDesc, page, itemsPerPage} = this.options
      let url = `/user/list?`
      if (this.role) {
        url += `role=${this.role}&`
      }
      url += `limit=${itemsPerPage}&offset=${(page - 1) * itemsPerPage}&sort=${sortBy}&desc=${sortDesc}&${this.querySeachParams}`;
      if (url !== this.lastFetchQuery) {
        this.lastFetchQuery = url;
        this.loading = true
        try {
          if (this.cancelToken) {
            this.cancelToken.cancel("Operation canceled due to new request.")
          }
          this.cancelToken = this.axios.CancelToken
          const {data, status} = await this.axios(url, {cancelToken: this.cancelToken});
          if (status !== 200) {
            this.loading = false;
            this.$root.$emit('notify', {message: `Server returned with status code: ${status}`, type: 'error'})
          }
          this.users = data.items.map(item => ({...item, roles: JSON.parse(item.roles), user_settings: JSON.parse(item.user_settings)}));
          if (data.total != this.totalUsers) {
            this.options.page = 1;
          }
          this.totalUsers = data.total;

        }
        catch (e) {
          console.error(e);
        }
        finally {
          this.loading = false
        }
      }
    },
  }

};
</script>
