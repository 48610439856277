<template>
  <UsersTable title="Students" :role="3"></UsersTable>
</template>
<script>
import UsersTable from "@/components/UserTable";
export default {
  name: 'Students',
  components: {UsersTable}
}
</script>
