<template>
  <div>
    <UsersTable title="Users"></UsersTable>
    <v-divider></v-divider>
    <UserBulkActions class="mt-6"></UserBulkActions>
  </div>
</template>
<script>
import UsersTable from "@/components/UserTable";
import UserBulkActions from "@/components/Users/UserBulkActions";
export default {
  name: 'Users',
  components: {UserBulkActions, UsersTable}
}
</script>
